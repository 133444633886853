import Vue from 'vue'
import App from './App.vue'
import router from './router'

import VueKinesis from 'vue-kinesis'
import VueRellax from 'vue-rellax'
import VueScrollSnap from "vue-scroll-snap";
import VueTypedJs from 'vue-typed-js'
import vuescroll from 'vuescroll';
import VueAnalytics from 'vue-analytics';

Vue.use(VueTypedJs)
Vue.use(VueKinesis)
Vue.use(VueRellax)
Vue.use(VueScrollSnap)
Vue.use(vuescroll)
Vue.use(VueAnalytics, {
  id: 'UA-217258575-1',
  router
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')