<template>
    <section id="modszertan" class="section features-area ptb_150">
        <div class="container my-4">
            <div class="row align-items-center">
                <div class="col-12 col-md-6 col-lg-6">
                    <div class="features-text">
                        <!-- Headings -->
                        <div class="headings d-flex align-items-center mb-4">
                            <span class="text-uppercase d-none d-sm-block">Folyamat</span>
                            <h2 class="text-capitalize">Hogyan dolgozunk?</h2>
                        </div>
                        <p class="my-3">Mi vár rád ha minket választasz? Egy pörgős és összeszokott csapat fogja minden tapasztalatával és megoldásaival segíteni az ötleted vagy vállalkozásod sikerességét. Beszéljük át céljaidat és mi megmutatjuk, hogyan juthatsz el hozzá a digitális téren keresztül.</p>
                        <!-- Counter List -->

                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-5 offset-lg-1">
                    <!-- Featured Items -->
                    <div class="featured-items">
                        <ul>
                            <li>
                                <!-- Single Features Item -->
                                <div class="single-features media p-2">
                                    <!-- Features Title -->
                                    <div class="features-title mr-3 my-auto">
                                        <span class="my-auto" style="font-size: 60px">🎯</span>
                                    </div>
                                    <!-- Features Text -->
                                    <div class="features-text media-body">
                                        <h3>Felmérés</h3>
                                        <p>Oszd meg velünk milyen kihívással nézel szembe, hogy együtt megtaláljuk a legjobb megoldást.<br><br></p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <!-- Single Features Item -->
                                <div class="single-features media p-2">
                                    <!-- Features Title -->
                                    <div class="features-title mr-3 my-auto">
                                        <span class="my-auto" style="font-size: 60px">📐</span>
                                    </div>
                                    <!-- Features Text -->
                                    <div class="features-text media-body">
                                        <h3>Tervezés</h3>
                                        <p>A felmérést követően csapatunk összeül, hogy felvázolja számodra a legoptimálisabb lehetőségeket.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <!-- Single Features Item -->
                                <div class="single-features media p-2">
                                    <!-- Features Title -->
                                    <div class="features-title mr-3 my-auto">
                                        <span class="my-auto" style="font-size: 60px">🚀</span>
                                    </div>
                                    <!-- Features Text -->
                                    <div class="features-text media-body">
                                        <h3>Fejlesztés / tanácsadás</h3>
                                        <p>Ideje nagyobb sebességre kapcsolni: legyen szó fejlesztésről vagy tanácsadásról elhozzuk számodra a megvalósítást.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <!-- Single Features Item -->
                                <div class="single-features media p-2">
                                    <!-- Features Title -->
                                    <div class="features-title mr-3 my-auto">
                                        <span class="my-auto" style="font-size: 60px">🧭</span>
                                    </div>
                                    <!-- Features Text -->
                                    <div class="features-text media-body">
                                        <h3>Nyomonkövetés</h3>
                                        <p>Folyamatosan monitorozzuk és elemezzük  az eredményeket, hogy a finomhangolást követően már új kihívásokról beszélgethessünk.</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>