<template>
    <section id="kapcsolat" class="start-free-area ptb_180 " style="background-image: url('/assets/img/stars.svg'), linear-gradient(to right,#1b1a1e 0,#315562 100%);">
        <div class="container" style="">
            <div class="col-12" >
                <!-- Start Free Content -->
                <!-- <kinesis-container>
                    <kinesis-element tag="div" :strength="1" type="scale" > -->
                        <div class="ptb_100 d-flex flex-wrap align-items-center justify-content-center justify-content-xl-between" data-aos="fade-up" >
                            <!-- Start Free Content -->
                            <div class="start-free-text">
                                <h2 class="mb-2"><span class="wave">👋</span> Van egy ötleted? <br> Keress meg minket, hogy átbeszélhessük!</h2>
                                <!-- <span>Reach us </span> -->
                            </div>
                            <!-- Start Free Button -->
                            <div class="start-free-btn mt-4 mt-lg-4">
                                <a id="contactUs" href="mailto:bejczi.andras@icloud.com" class="btn"><span>Kapcsolatfelvétel</span></a>
                            </div>
                        </div>
                    <!-- </kinesis-element>
                </kinesis-container> -->
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>