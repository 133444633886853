<template>
    <section id="home" class="section welcome-area bg-inherit h-100vh">
        <div class="shapes-container">
            <div class="shape bg-shape"></div>
        </div>
        <div class="container h-100">
            <div class="row align-items-center h-100">
                <!-- Welcome Intro Start -->
                <div class="col-12 col-md-7 col-lg-6">
                    <div class="welcome-intro text-right">
                        <h1>Creative way to Land your page</h1>
                        <p class="my-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Temporibus commodi, voluptate quaerat iure quidem expedita eos a blanditiis sint modi est error veniam facere eum at doloribus amet, nobis ut.</p>
                        <a href="#" class="btn btn-bordered d-none d-md-inline-block">Get Started</a>
                        <a href="#" class="btn btn-bordered-white d--inline-block d-md-none">Get Started</a>
                    </div>
                </div>
                <div class="col-12 col-md-5 col-lg-6">
                    <!-- Welcome Thumb -->
                    <div class="welcome-thumb text-center" data-aos="fade-right" data-aos-delay="500" data-aos-duration="1000">
                        <img src="assets/img/welcome_mockup_2.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>