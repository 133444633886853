<template>
    <section class="section about-us ptb_100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-8 col-md-6 col-lg-5 mx-auto mb-5 mb-lg-0">
                    <img class="mx-auto" src="assets/img/about_thumb.png" alt="">
                </div>
                <div class="col-12 col-lg-6">
                    <h2>We make Unique Websites to Showcase your apps</h2>
                    <p class="my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, eaque. Assumenda modi debitis voluptatem eum delectus. Accusantium voluptate, facilis expedita fuga delectus explicabo vel, dicta ipsam! Repudiandae commodi neque atque asperiores, dolore. Deleniti sint aperiam officiis fuga quia laborum reprehenderit voluptates suscipit assumenda, accusamus aliquam soluta nesciunt facere, amet nihil?</p>
                    <!-- Counter List -->
                    <div class="counter-list">
                        <ul>
                            <li>
                                <!-- Single Counter -->
                                <div class="single-counter px-4 py-3 text-center">
                                    <!-- Counter Icon -->
                                    <div class="counter-icon">
                                        <i class="icofont-repair"></i>
                                    </div>
                                    <!-- Counter Text -->
                                    <div class="counter-text">
                                        <span class="counter d-inline-block mt-3 mb-2">2350</span>
                                        <h5>Users</h5>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <!-- Single Counter -->
                                <div class="single-counter px-4 py-3 text-center">
                                    <!-- Counter Icon -->
                                    <div class="counter-icon">
                                        <i class="icofont-heart-alt"></i>
                                    </div>
                                    <!-- Counter Text -->
                                    <div class="counter-text">
                                        <span class="counter d-inline-block mt-3 mb-2">1895</span>
                                        <h5>Acounts</h5>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <!-- Single Counter -->
                                <div class="single-counter px-4 py-3 text-center">
                                    <!-- Counter Icon -->
                                    <div class="counter-icon">
                                        <i class="icofont-beard"></i>
                                    </div>
                                    <!-- Counter Text -->
                                    <div class="counter-text">
                                        <span class="counter d-inline-block mt-3 mb-2">1580</span>
                                        <h5>Developers</h5>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <!-- Single Counter -->
                                <div class="single-counter px-4 py-3 text-center">
                                    <!-- Counter Icon -->
                                    <div class="counter-icon">
                                        <i class="icofont-safety"></i>
                                    </div>
                                    <!-- Counter Text -->
                                    <div class="counter-text">
                                        <span class="counter d-inline-block mt-3 mb-2">1358</span>
                                        <h5>Download</h5>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>