<template>
    <footer class="section footer-area footer-bg">
        <!-- Footer Top -->
        <!-- Footer Bottom -->
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <!-- Copyright Area -->
                        <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                            <!-- Copyright Left -->
                            <div class="copyright-left text-white-50">&copy; Copyrights 2021 markit.space All rights reserved.</div>
                            <!-- Copyright Right -->
                            <div class="copyright-right text-white-50">Made with <i class="icofont-heart-alt"></i> By <a class="text-white-50" href="#">markit.space</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>