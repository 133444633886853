<template>
    <section id="csapat" class="section team-area ptb_100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-6">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2 class="text-capitalize">Ismerd meg csapatunkat</h2>
                        <!-- <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                        <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p> -->
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- Team Slider -->
                <div class="team-slider owl-carousel">
                    <!-- Single Team -->
                    <kinesis-container>
                        <kinesis-element tag="div" :strength="10" type="depth" >
                            <div class="single-team text-center px-3 py-2 m-2" >
                                <!-- Team Photo -->
                                <div class="team-photo mx-auto">
                                    <img src="assets/img/hj.jpg" alt="Hella Jánosi">
                                </div>
                                <!-- Team Content -->
                                <div class="team-content mt-2 mb-1" >
                                    <h3 class="team-name mt-3 mb-2">Hella Jánosi</h3>
                                    <h5 class="team-post mb-2">Marketing &amp; Social</h5>
                                    <p class="team-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt, ex quibusdam aliquid dicta a explicabo.</p>
                                    <!-- Team Media -->
                                    <div class="team-media mt-3">
                                        <a href="#">
                                            <i class="fab fa-facebook-f"></i>
                                        </a>
                                        <a href="#">
                                            <i class="fab fa-twitter"></i>
                                        </a>
                                        <a href="#">
                                            <i class="fab fa-google-plus-g"></i>
                                        </a>
                                        <a href="#">
                                            <i class="fab fa-instagram"></i>
                                        </a>
                                        <a href="#">
                                            <i class="fab fa-linkedin-in"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </kinesis-element>
                    </kinesis-container>
                    <!-- Single Team -->
                    <kinesis-container>
                        <kinesis-element tag="div" :strength="10" type="depth" >
                            <div class="single-team text-center px-3 py-2 m-2">
                                <!-- Team Photo -->
                                <div class="team-photo mx-auto">
                                    <img src="assets/img/ab.jpeg" alt="András Bejczi">
                                </div>
                                <!-- Team Content -->
                                <div class="team-content mt-2 mb-1">
                                    <h3 class="team-name mt-3 mb-2">András Bejczi</h3>
                                    <h5 class="team-post mb-2">Project management &amp; Strategy</h5>
                                    <p class="team-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt, ex quibusdam aliquid dicta a explicabo.</p>
                                    <!-- Team Media -->
                                    <div class="team-media mt-3">
                                        <a href="#">
                                            <i class="fab fa-facebook-f"></i>
                                        </a>
                                        <a href="#">
                                            <i class="fab fa-twitter"></i>
                                        </a>
                                        <a href="#">
                                            <i class="fab fa-google-plus-g"></i>
                                        </a>
                                        <a href="#">
                                            <i class="fab fa-instagram"></i>
                                        </a>
                                        <a href="#">
                                            <i class="fab fa-linkedin-in"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </kinesis-element>
                    </kinesis-container>
                    <!-- Single Team -->
                    <kinesis-container>
                        <kinesis-element tag="div" :strength="10" type="depth" >
                            <div class="single-team text-center px-3 py-2 m-2">
                                <!-- Team Photo -->
                                <div class="team-photo mx-auto">
                                    <img src="assets/img/bk.jpeg" alt="Botond Kránicz">
                                </div>
                                <!-- Team Content -->
                                <div class="team-content mt-2 mb-1">
                                    <h3 class="team-name mt-3 mb-2">Botond Kránicz</h3>
                                    <h5 class="team-post mb-2">Technical &amp; Product development</h5>
                                    <p class="team-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt, ex quibusdam aliquid dicta a explicabo.</p>
                                    <!-- Team Media -->
                                    <div class="team-media mt-3">
                                        <a href="#">
                                            <i class="fab fa-facebook-f"></i>
                                        </a>
                                        <a href="#">
                                            <i class="fab fa-twitter"></i>
                                        </a>
                                        <a href="#">
                                            <i class="fab fa-google-plus-g"></i>
                                        </a>
                                        <a href="#">
                                            <i class="fab fa-instagram"></i>
                                        </a>
                                        <a href="#">
                                            <i class="fab fa-linkedin-in"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </kinesis-element>
                    </kinesis-container>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>