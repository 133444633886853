<template>
    <section id="rolunk" class="section about-app-area ptb_150">
        <div class="shapes-container">
            <div class="shape-1"></div>
        </div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-lg-6">
                    <!-- About Text -->
                    <div class="about-text">
                        <!-- Headings -->
                        <div class="headings d-flex align-items-center mb-4">
                            <span class="text-uppercase d-none d-sm-block">Rólunk</span>
                            <h2 class="text-capitalize">Ismerj meg minket</h2>
                        </div>
                        <p class="my-3">Fiatalos csapatunk a rendelkezésedre áll online és offline megoldások terén is. Több mint 100 sikeres projekttel a hátunk mögött minden alkalommal készen állunk az új kihívásokra, hogy együtt tegyük még sikeresebbé a vállalkozásodat.</p>
                        <p class="d-none d-sm-block my-3">Csapatunk olyan vállalati környezetben szerzett tapasztalatot, mint Telenor Magyarország vagy MOL, ezt követően pedig igyekeztünk a leghatékonyabban átültetni a tapasztalati és stratégia szemléletünket a hazai kis és középvállalkozói szektorba is.</p>
                        <!-- Store Buttons -->
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <!-- About Thumb -->
                    <div class="about-thumb text-center">
                        <img src="assets/img/about.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>