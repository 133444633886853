<template>
  <div class="all-area">
      <div class="inner-wrapper d-flex flex-column align-items-center justify-content-between p-4">
        <a href="/">
            <img src="assets/img/inner_logo.png" alt="">
        </a>
        <!-- ***** Newsletter Area Start ***** -->
        <div class="newsletter-area mb-5 mb-lg-0">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 col-md-6 col-lg-5 mx-auto order-2 order-md-1">
                        <h2 class="text-primary">Subscribe to get updates!</h2>
                        <p class="my-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto beatae, asperiores tempore repudiandae saepe aspernatur unde voluptate sapiente quia ex.</p>
                        <div class="input-group reset-password">
                            <input type="email" class="form-control" placeholder="Enter Your Email" aria-label="Recipient's username">
                            <div class="input-group-append mt-3 mt-sm-0">
                                <button class="btn btn-primary" type="submit">Subscribe</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-8 col-md-6 mx-auto mb-5 mb-md-0 order-1 order-md-2 pt-4 pt-md-0">
                        <img src="assets/img/newsletter_thumb.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <!-- ***** Newsletter Area End ***** -->
        <div class="footer-bottom">
            <!-- Copyright Area -->
            <div class="copyright-area border-0 pt-4 pt-md-0">
                <p>Made with <i class="icofont-heart-alt"></i> By <a href="#">Theme Land</a></p>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>