<template>
  <div>
    <ScrollupSection />
    <div class="all-area">
      <HeaderSection />
      <BreadcrumbSection />
      <section class="section testimonial-area bg-gray ptb_100">
            <div class="container text-center">
                <div class="row justify-content-center align-items-center">
                    <div class="col-12 col-md-8">
                        <div class="testimonials owl-carousel">
                            <!-- Single Testimonial -->
                            <div class="single-testimonial p-3 p-md-5">
                                <img src="assets/img/avatar_1.png" class="mx-auto d-block" alt="">
                                <!-- Client Name -->
                                <h3 class="client-name mt-4 mb-2">John Doe</h3>
                                <!-- Client Address -->
                                <h5 class="client-address fw-4">Los Angeles, California</h5>
                                <!-- Client Rating -->
                                <div class="client-rating mt-2 mb-3">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="far fa-star"></i>
                                </div>
                                <!-- Client Description -->
                                <div class="client-description">
                                    <!-- Client Text -->
                                    <div class="client-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam rem sunt nulla ducimus expedita, incidunt laborum assumenda. Deleniti iste placeat nostrum incidunt rem laudantium, sapiente, cum, molestias unde, quidem labore.</p>
                                    </div>
                                </div>
                            </div>
                            <!-- Single Testimonial -->
                            <div class="single-testimonial p-3 p-md-5">
                                <img src="assets/img/avatar_2.png" class="mx-auto d-block" alt="">
                                <!-- Client Name -->
                                <h3 class="client-name mt-4 mb-2">Jassica William</h3>
                                <!-- Client Address -->
                                <h5 class="client-address fw-4">Los Angeles, California</h5>
                                <!-- Client Rating -->
                                <div class="client-rating mt-2 mb-3">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                </div>
                                <!-- Client Description -->
                                <div class="client-description">
                                    <!-- Client Text -->
                                    <div class="client-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam rem sunt nulla ducimus expedita, incidunt laborum assumenda. Deleniti iste placeat nostrum incidunt rem laudantium, sapiente, cum, molestias unde, quidem labore.</p>
                                    </div>
                                </div>
                            </div>
                            <!-- Single Testimonial -->
                            <div class="single-testimonial p-3 p-md-5">
                                <img src="assets/img/avatar_3.png" class="mx-auto d-block" alt="">
                                <!-- Client Name -->
                                <h3 class="client-name mt-4 mb-2">Johnson Smith</h3>
                                <!-- Client Address -->
                                <h5 class="client-address fw-4">Los Angeles, California</h5>
                                <!-- Client Rating -->
                                <div class="client-rating mt-2 mb-3">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="far fa-star"></i>
                                </div>
                                <!-- Client Description -->
                                <div class="client-description">
                                    <!-- Client Text -->
                                    <div class="client-text">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam rem sunt nulla ducimus expedita, incidunt laborum assumenda. Deleniti iste placeat nostrum incidunt rem laudantium, sapiente, cum, molestias unde, quidem labore.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Single Testimonial Thumb -->
                        <div class="single-testimonial-thumb d-none d-md-block">
                            <!-- Thumb Prev -->
                            <div class="thumb-prev">
                                <span>
                                    <img src="assets/img/avatar_3.png" alt="">
                                </span>
                            </div>
                            <!-- Thumb Next -->
                            <div class="thumb-next">
                                <span>
                                    <img src="assets/img/avatar_2.png" alt="">
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      <NewsletterSection />
      <FooterSection />
    </div>
  </div>
</template>

<script>
import ScrollupSection from '../../Scrollup/Scrollup'
import HeaderSection from '../../Header/HeaderOne'
import BreadcrumbSection from '../../Breadcrumb/Reviews/Reviews'
import NewsletterSection from '../../Newsletter/Newsletter'
import FooterSection from '../../Footer/Footer'

export default {
  name: 'Reviews',
  components: {
    ScrollupSection,
    HeaderSection,
    BreadcrumbSection,
    NewsletterSection,
    FooterSection
  }
}
</script>

<style>

</style>