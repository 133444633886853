<template>
    <header class="section header-area">
        <div id="appo-header" class="main-header-area nav-white">
            <div class="container-fluid" style="padding-top:15px">
                <nav class="navbar navbar-expand-md navbar-light">
                    <!-- Logo -->
                    <a class="navbar-brand" href="/">
                        <img v-if="width > 900" class="logo" src="assets/img/markit-space-logo--light@2048.png" alt="markit.space">
                        <img v-else class="logo" src="assets/img/markit-space-icon--dark@2048.png" alt="markit.space">
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#appo-menu">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <!-- Appo Menu -->
                    <div class="collapse navbar-collapse" id="appo-menu">
                        <!-- Header Items -->
                        <ul class="navbar-nav header-items ml-auto">
                            <li class="nav-item my-auto">
                                <a class="nav-link scroll" href="#szolgaltatasok">Szolgáltatásaink</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link scroll" href="#rolunk">Rólunk</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link scroll" href="#modszertan">Módszertan</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link scroll" href="#projektek">Projektek</a>
                            </li>
                            <!-- <li class="nav-item">
                                <a class="nav-link scroll" href="#csapat">Csapat</a>
                            </li> -->
                            <li class="nav-item">
                                <a class="nav-link scroll" href="#kapcsolat">Kapcsolat</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    data() {
        return {
            width: window.innerWidth,
        }
    },
    mounted() {
        window.addEventListener('resize', () => {         
            this.width = window.innerWidth       
        });
    }
}
</script>

<style>

</style>