<template>
    <section id="benifits" class="section benifits-area ptb_100">
        <div class="container">
            <div class="row">
                <!-- Benifits Item -->
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="benifits-item text-center p-3">
                        <div class="feature-icon">
                            <img src="assets/img/promo_icon_1.png" alt="">
                        </div>
                        <!-- Benifits Text -->
                        <div class="benifits-text">
                            <h3 class="mb-2">Free Trail</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci, ipsum.</p>
                        </div>
                    </div>
                </div>
                <!-- Benifits Item -->
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="benifits-item text-center p-3">
                        <div class="feature-icon">
                            <img src="assets/img/promo_icon_2.png" alt="">
                        </div>
                        <!-- Benifits Text -->
                        <div class="benifits-text">
                            <h3 class="mb-2">Easy to use</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci, ipsum.</p>
                        </div>
                    </div>
                </div>
                <!-- Benifits Item -->
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="benifits-item text-center p-3">
                        <div class="feature-icon">
                            <img src="assets/img/promo_icon_3.png" alt="">
                        </div>
                        <!-- Benifits Text -->
                        <div class="benifits-text">
                            <h3 class="mb-2">24/7 Support</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci, ipsum.</p>
                        </div>
                    </div>
                </div>
                <!-- Benifits Item -->
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="benifits-item text-center p-3">
                        <div class="feature-icon">
                            <img src="assets/img/promo_icon_4.png" alt="">
                        </div>
                        <!-- Benifits Text -->
                        <div class="benifits-text">
                            <h3 class="mb-2">Well Documented</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci, ipsum.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>