<template>
    <section class="section work-area ptb_100">
        <!-- Work Slider Wrapper -->
        <div class="work-wrapper d-none d-md-block">
            <div class="work-slider-wrapper" data-aos="zoom-in">
                <!-- Work Slider -->
                <ul class="work-slider owl-carousel">
                    <li class="slide-item">
                        <img src="assets/img/screenshot_1.png" alt="">
                    </li>
                    <li class="slide-item">
                        <img src="assets/img/screenshot_2.png" alt="">
                    </li>
                    <li class="slide-item">
                        <img src="assets/img/screenshot_3.png" alt="">
                    </li>
                    <li class="slide-item">
                        <img src="assets/img/screenshot_4.png" alt="">
                    </li>
                </ul>
            </div>
        </div>
        <div class="container">
            <div class="row justify-content-center d-md-none">
                <!-- Section Heading -->
                <div class="col-12 col-md-10 col-lg-6">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                        <h2 class="text-capitalize">Few steps to Setup</h2>
                        <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                        <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                    </div>
                </div>
            </div>
            <!-- Work Content -->
            <div class="row justify-content-end justify-content-lg-between work-content" id="work-slider-pager">
                <div class="col-12 col-sm-6">
                    <a href="#" class="pager-item active">
                        <!-- Single Work -->
                        <div class="single-work d-inline-block text-center p-4">
                            <h3 class="mb-2">Install</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repudiandae fugit labore delectus odio nisi eaque?</p>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6">
                    <a href="#" class="pager-item">
                        <!-- Single Work -->
                        <div class="single-work d-inline-block text-center p-4">
                            <h3 class="mb-2">Register</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repudiandae fugit labore delectus odio nisi eaque?</p>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6">
                    <a href="#" class="pager-item">
                        <!-- Single Work -->
                        <div class="single-work d-inline-block text-center p-4">
                            <h3 class="mb-2">Setup profile</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repudiandae fugit labore delectus odio nisi eaque?</p>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-sm-6">
                    <a href="#" class="pager-item">
                        <!-- Single Work -->
                        <div class="single-work d-inline-block text-center p-4">
                            <h3 class="mb-2">Done</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repudiandae fugit labore delectus odio nisi eaque?</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>