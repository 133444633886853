<template>
    <section id="szolgaltatasok" class="section benifits-area ptb_100" style="background: url('/assets/img/stars2.svg'">
        <div class="">
            <div class="row my-4">
                <div class="col-xl-3 col-12" style="padding: 1px">
                    <div class="promo-card text-center" style="background-position: top;">
                        <div class="promo-content text-center p-4">
                            <h2 class="mb-1">Szoftverfejlesztés</h2>
                            <p class="mb-3 text-bold">Hogy az ötletedből kézzelfogható, magas minőségű termék legyen</p>
                            <!-- <div class="feature-icon mb-4">
                                <img src="assets/img/features_icon_5.png" alt="">
                            </div> -->
                            <div class="feature-icon mb-4">
                                <div class="feature-emoji">🚀</div> 
                            </div>
                            <div class="benifits-text">
                                <p>Full-stack megoldások, webfejlesztés, backend, integráció, automatizálás.</p>
                                <a class="my-4 btn" href="#kapcsolat">Ez érdekel</a>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="col-xl-3 col-12" style="padding: 1px">
                    <div class="promo-card text-center" style="background-position: left;">
                        <div class="promo-content text-center p-4">
                            <h2 class="mb-1">Marketing</h2>
                            <p class="mb-3 text-bold">Hogy mindenkihez eljuss az online és az offline világban</p>
                            <!-- <div class="feature-icon mb-4">
                                <img src="assets/img/features_icon_1.png" alt="">
                            </div> -->
                            <div class="feature-icon mb-4">
                                <div class="feature-emoji">👾</div> 
                            </div>
                            <div class="benifits-text">
                                <p>Arculat és branding tervezés, social media és email kampányok, SEO.</p>
                                <a class="my-4 btn" href="#kapcsolat">Ez érdekel</a>
                            </div>
                        </div> 
                    </div>
                </div>              
                <div class="col-xl-3 col-12" style="padding: 1px">
                    <div class="promo-card text-center">
                        <div class="promo-content text-center p-4" style="background-position: bottom;">
                            <h2 class="mb-1">Tanácsadás</h2>
                            <p class="mb-3 text-bold">Hogy vállalkozásod a legjobb úton haladjon a jövőben</p>
                            <div class="feature-icon mb-4">
                                <div class="feature-emoji">☄️</div> 
                            </div>
                            <div class="benifits-text">
                                <p>Üzleti és IT tanácsadás, stratégia alkotás, projekt menedzsment.</p>
                                <a class="my-4 btn" href="#kapcsolat">Ez érdekel</a>
                            </div>
                        </div> 
                    </div>
                </div>              
                <div class="col-xl-3 col-12" style="padding: 1px">
                    <div class="promo-card text-center" style="background-position: right;">
                        <div class="promo-content text-center p-4">
                            <h2 class="mb-1">Üzemeltetés</h2>
                            <p class="mb-3 text-bold">Hogy a terméked mindig naprakész és gyorsan elérhető legyen</p>
                            <!-- <div class="feature-icon mb-4">
                                <img src="assets/img/features_icon_3.png" alt="">
                            </div> -->
                            <div class="feature-icon mb-4">
                                <div class="feature-emoji">🛰️</div> 
                            </div>
                            <div class="benifits-text">
                                <p>Weboldal és alkalmazás üzemeltetés, karbantartás, folyamatos rendelkezésre állás</p>
                                <a class="my-4 btn" href="#kapcsolat">Ez érdekel</a>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="benifits-item text-center p-3">
                        <div class="feature-icon">
                            <img src="assets/img/features_icon_5.png" alt="">
                        </div>
                        <div class="benifits-text">
                            <h3 class="mb-2">Szoftverfejlesztés</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum impedit possimus eligendi, quam soluta qui.</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="benifits-item text-center p-3">
                        <div class="feature-icon">
                            <img src="assets/img/features_icon_1.png" alt="">
                        </div>
                        <div class="benifits-text">
                            <h3 class="mb-2">Marketing & branding</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum impedit possimus eligendi, quam soluta qui.</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                     <div class="benifits-item text-center p-3">
                        <div class="feature-icon">
                            <img src="assets/img/features_icon_4.png" alt="">
                        </div>
                        <div class="benifits-text">
                            <h3 class="mb-2">Stratégia</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum impedit possimus eligendi, quam soluta qui.</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="benifits-item text-center p-3">
                        <div class="feature-icon">
                            <img src="assets/img/features_icon_3.png" alt="">
                        </div>
                        <div class="benifits-text">
                            <h3 class="mb-2">Üzemeltetés</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum impedit possimus eligendi, quam soluta qui.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </section>
</template>

<script>
export default {

}
</script>

<style>

.feature-icon {
    height: 120px;
}

.feature-emoji {
    font-size: 100px;
    padding-top: 40px;
}
.promo-card {
    /* background:#30353C; */
    background: rgba(0, 0, 0, 0);
    background-image: url("/assets/img/stars.svg"), linear-gradient(to top,#30353C 0,#30353C 100%);
    /* min-height: 500px; */
    padding-top: 100px;
    padding-bottom: 120px;
    color: white !important;
    transition: all .8s;
    max-height: 550px;
    overflow: hidden;
}

@media (max-width: 991px) {
    .promo-card {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.promo-card a {
    display: none;
}

.promo-card:hover { 
    background:linear-gradient(to bottom, #e49b91 55%, #f8d9af 100%);
    color: black !important;
}

.promo-content {
    transition: all .8s;
    color: white;
}

.promo-content p,
.promo-content h2 {
    color: white;
}

.promo-card img {
        transition: all .8s;
}

.promo-card:hover img {
  transform: scale(1.2);
}

.promo-card:hover a {
    cursor: pointer;
    display: block;
    width: auto;
    background: #30353C;
    color: #fff;
    border: 1px solid #30353C;
    margin-left: auto;
    margin-right: auto;
}

.promo-card:hover .promo-content p,
.promo-card:hover .promo-content h2 {
    color: #30353C;
}
</style>