<template>
  <div class="london bg-dark">
    <ScrollupSection />
    <div class="all-area">
      <HeaderSection />
      <HeroSection />
      <PromoSection />
      <AboutSection />
      <FeatureSection />
      <ScreenshotSection />
      <ReviewSection />
      <NewsletterSection />
      <FooterSection />
    </div>
  </div>
</template>

<script>
import ScrollupSection from '../components/Scrollup/Scrollup'
import HeaderSection from '../components/Header/HeaderTwo'
import HeroSection from '../components/Hero/HeroSix'
import PromoSection from '../components/Promo/PromoTwo'
import AboutSection from '../components/About/About'
import FeatureSection from '../components/Features/Features'
import ScreenshotSection from '../components/Screenshots/Screenshots'
import ReviewSection from '../components/Reviews/Reviews'
import NewsletterSection from '../components/Newsletter/Newsletter'
import FooterSection from '../components/Footer/Footer'

export default {
  name: 'ThemeSix',
  components: {
    ScrollupSection,
    HeaderSection,
    HeroSection,
    PromoSection,
    AboutSection,
    FeatureSection,
    ScreenshotSection,
    ReviewSection,
    NewsletterSection,
    FooterSection
  }
}
</script>

<style>

</style>