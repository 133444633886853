<template>
    <section class="section testimonial-area ptb_100">
        <div class="shapes-container">
            <!-- <div class="shape bg-shape"></div> -->
        </div>
        <div class="buzz">
            <kinesis-container event="scroll" :duration="2000">
                <kinesis-element tag="h1" :strength="200" :originX="200" type="translate" axis="x" >
                    <h1>
                        <span :class="width < 992 ? 'active': null">Marketing</span>
                        <span>Marketing</span>
                        <span :class="width >= 992 ? 'active': null">Marketing</span>
                        <span>Marketing</span>
                        <span>Marketing</span>
                        <span>Marketing</span>
                        <span>Marketing</span>
                        <span>Marketing</span>
                    </h1>
                </kinesis-element>
            </kinesis-container>
            <kinesis-container event="scroll" :duration="2000">
                <kinesis-element tag="h1" :strength="-200" :originX="100" type="translate" axis="x" >
                    <h1>
                        <span>Design</span>
                        <span :class="width < 992 ? 'active': null">Design</span>
                        <span>Design</span>
                        <span>Design</span>
                        <span :class="width >= 992 ? 'active': null" >Design</span>
                        <span>Design</span>
                        <span>Design</span>
                        <span>Design</span>
                        <span>Design</span>
                        <span>Design</span>
                        <span>Design</span>
                        <span>Design</span>
                    </h1>
                </kinesis-element>
            </kinesis-container>
            <kinesis-container event="scroll" :duration="2000">
                <kinesis-element tag="h1" :strength="-100" :originX="-100" type="translate" axis="x" >
                    <h1>
                        <span>Strategy</span>
                        <span class="active">Strategy</span>
                        <span>Strategy</span>
                        <span>Strategy</span>
                        <span>Strategy</span>
                        <span>Strategy</span>
                        <span>Strategy</span>
                        <span>Strategy</span>
                    </h1>
                </kinesis-element>
            </kinesis-container>
            <kinesis-container event="scroll" :duration="2000">
                <kinesis-element tag="h1" :strength="100" :originX="-100" type="translate" axis="x" >
                    <h1>
                        <span :class="width < 992 ? 'active': null">Development</span>
                        <span>Development</span>
                        <span :class="width >= 992 ? 'active': null">Development</span>
                        <span>Development</span>
                        <span>Development</span>
                        <span>Development</span>
                    </h1>
                </kinesis-element>
            </kinesis-container>
        </div>
        <div class="container text-center">
            <div class="row justify-content-center align-items-center">
                <div class="col-12 col-md-8">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return { 
            width: window.innerWidth
        }
    },
    mounted() {
        window.addEventListener('resize', () => {         
            this.width = window.innerWidth       
        });
    }
}
</script>

<style>

</style>