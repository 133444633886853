<template>
  <section
    id="projektek"
    class="section ptb_100"
    style="background: linear-gradient(90deg, #1b1a1e 20px, transparent 1%) center, linear-gradient(#1b1a1e 20px, transparent 1%) center, #f8d9af; background-size: 22px 22px;"
  >
    <div class="container" style="background: #1b1a1e;">
      <div class="row align-items-center">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="features-text">
            <div class="headings d-flex align-items-center mb-4 ptb_100">
              <span class="text-uppercase d-none d-sm-block">Kiemelt</span>
              <h2 class="text-capitalize">Projektjeink</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="row item mb-4">
        <div class="col-xl-6">
          <div>
            <h2 class="text-white">
              <a href="https://portfolion.hu" target="_blank"
                >Portfolion
                <span style="font-size: 16px;">(OTP csoport)</span></a
              >
            </h2>
            <h2 class="text-white ptb_100">
              Webes arculat újratervezése és teljeskörű megvalósítása, a
              háttérrendszerekkel együtt
            </h2>
            <h3 class="text-white">Web, CMS, design, full-stack, analytics</h3>
          </div>
        </div>
        <div class="col-xl-6">
          <img src="/assets/img/port-fn.jpg" alt="" />
        </div>
      </div>
      <div class="row flex-column-reverse flex-xl-row item item mb-4">
        <div class="col-xl-6">
          <img src="/assets/img/deliduna-fn.jpg" alt="" height="100%" />
        </div>
        <div class="col-xl-6">
          <div>
            <h2 class="text-white">
              <a href="http://delidunakanyar.hu" target="_blank"
                >Déli Dunakanyar</a
              >
            </h2>
            <h2 class="text-white ptb_100">
              Térkép-alapú, interaktív online útikönyv és blog a térség
              felpörgetéséhez
            </h2>
            <h3 class="text-white">Web, backend, admin, design, integráció</h3>
          </div>
        </div>
      </div>
      <div class="row item mb-4">
        <div class="col-xl-6">
          <div>
            <h2 class="text-white">
              <a href="https://mvconsulting.hu" target="_blank"
                >MV Consulting</a
              >
            </h2>
            <h2 class="text-white ptb_100">
              Teljes arculat újratervezés és új webloldal fejlesztése
            </h2>
            <h3 class="text-white">Design, web, backend</h3>
          </div>
        </div>
        <div class="col-xl-6">
          <img src="/assets/img/mvcons-1.jpg" alt="" height="100%" />
        </div>
      </div>
      <div class="row flex-column-reverse flex-xl-row item">
        <div class="col-xl-6">
          <img src="/assets/img/joy-fin.jpg" alt="" height="100%" />
        </div>
        <div class="col-xl-6">
          <div>
            <h2 class="text-white">
              <a href="https://www.joycruise.hu" target="_blank">Joycruise</a>
            </h2>
            <h2 class="text-white ptb_100">
              Landing oldal és adminisztratív háttérrendszer a foglalások
              kezelésére
            </h2>
            <h3 class="text-white">Web, backend, admin, design</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/*  import VueScrollSnap from "vue-scroll-snap"; */

export default {
  components: {
    /*  VueScrollSnap */
  },
  data() {
    return {
      type: "h",
    };
  },
};
</script>

<style scoped>
.item {
  /* Set the minimum height of the items to be the same as the height of the scroll-snap-container.*/
  min-height: 800px;
}

.scroll-snap-container {
  height: 800px;
}

.item h2 a {
  color: white !important;
}

a {
  color: #fff;
  text-decoration: none;
  display: inline-block;
  position: relative;
}
a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
a:hover:after {
  width: 100%;
  left: 0;
}
</style>
