<template>
    <div id="scrollUp" title="Scroll To Top" style="transform: rotate(-45deg);">
        <span style="font-size: 40px">🚀</span>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>