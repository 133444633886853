<template>
    <section class="branding-area ptb_50">
        <div class="container">
            <div class="row">
                <!-- Branding Slider -->
                <div class="branding-slider owl-carousel op-5 py-5 border-bottom">
                    <!-- Single Brand -->
                    <div class="single-brand p-3">
                        <img src="assets/img/branding_img_1.png" alt="">
                    </div>
                    <!-- Single Brand -->
                    <div class="single-brand p-3">
                        <img src="assets/img/branding_img_2.png" alt="">
                    </div>
                    <!-- Single Brand -->
                    <div class="single-brand p-3">
                        <img src="assets/img/branding_img_3.png" alt="">
                    </div>
                    <!-- Single Brand -->
                    <div class="single-brand p-3">
                        <img src="assets/img/branding_img_4.png" alt="">
                    </div>
                    <!-- Single Brand -->
                    <div class="single-brand p-3">
                        <img src="assets/img/branding_img_5.png" alt="">
                    </div>
                    <!-- Single Brand -->
                    <div class="single-brand p-3">
                        <img src="assets/img/branding_img_6.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>