<template>
    <section id="home" class="section welcome-area d-flex align-items-center h-100vh">
        <div class="container">
            <div class="row">
                <!-- Welcome Intro Start -->
                <div class="col-12 col-md-7 col-lg-7">
                    <div class="welcome-intro" style="min-height: 300px">
                        <!-- <h1 class="text-white">Creative way to Land your page</h1>
                        <p class="text-white my-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Temporibus commodi, voluptate quaerat iure quidem expedita eos a blanditiis sint modi est error veniam facere eum at doloribus amet, nobis ut.</p>
                        <a href="#" class="btn btn-bordered text-white"><span>Get Started</span></a> -->
                        <vue-typed-js :strings="texts" :loop="true" :typeSpeed="100" :backSpeed="60" :contentType="'html'">
                            <h1 class="text-white">Hello! Mi vagyunk a markit.space. Segítünk neked <br><span class="typing"></span></h1>
                        </vue-typed-js>
                    </div>
                </div>
                <div class="col-12 col-md-5 mx-auto pt-4 pt-md-0">
                    <!-- Welcome Thumb -->
                    <div class="welcome-thumb">
                        <!-- <img src="assets/img/hero.svg" alt="Markit.space"> -->
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 600 500" style="enable-background:new 0 0 600 500;" xml:space="preserve">

<radialGradient id="Background_1_" cx="553.3989" cy="90.4349" r="611.532" gradientUnits="userSpaceOnUse">
	<stop  offset="0" style="stop-color:#273B45"/>
	<stop  offset="1" style="stop-color:#1B1A1E"/>
</radialGradient>
<path id="Background" class="st0" d="M247.9,495c-125.1-22.8-76.1-99.1-204.4-195.2C-128.8,170.7,251.4-59.7,480.1,14.4
	c107.1,34.7,146,104.1,102.3,263.4C528.7,473.2,365,516.4,247.9,495z"/>
<g id="Comet" class="st1">
	
		<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="106.0757" y1="131.301" x2="-11.5302" y2="231.1853" gradientTransform="matrix(0.9885 0.1511 -0.1511 0.9885 85.2229 91.2303)">
		<stop  offset="5.263160e-03" style="stop-color:#FFFFFF"/>
		<stop  offset="0.9895" style="stop-color:#FFFFFF;stop-opacity:0"/>
	</linearGradient>
	<path class="st2" d="M142.6,260.2l-86.2,42.2l81.8-51.6c2.4-1.5,5.4-0.6,6.7,2c1.3,2.6,0.4,5.8-2,7.3
		C142.8,260.1,142.7,260.1,142.6,260.2z"/>
</g>
<g id="Nabulae">
	<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="430.4231" y1="623.3169" x2="416.2789" y2="340.4332">
		<stop  offset="5.263160e-03" style="stop-color:#FFFFFF"/>
		<stop  offset="0.9895" style="stop-color:#FFFFFF;stop-opacity:0"/>
	</linearGradient>
	<path class="st3" d="M596,215.7c-10.6,1.6-21.1,6.5-29.9,12.7c-42,29.8-59.3,83.9-95.8,120.3c-51.7,51.6-133,60-192,103.1
		c-2.7,2-5.4,4.1-8,6.3c-12.3,10.2-23.1,22-32.3,35c3.2,0.7,6.4,1.4,9.9,2c117.1,21.4,280.8-21.8,334.5-217.3
		C588.5,255.3,593,234.7,596,215.7z"/>
	<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="816.4601" y1="513.6141" x2="417.1234" y2="42.5207">
		<stop  offset="5.263160e-03" style="stop-color:#FFFFFF"/>
		<stop  offset="0.9895" style="stop-color:#FFFFFF;stop-opacity:0"/>
	</linearGradient>
	<path class="st4" d="M597.8,136c-9.5-63.8-49.9-99.7-117.7-121.7c-65-21.1-142.2-17.5-215.6,1.3c20.7,26,50.7,44.7,83.3,51.4
		c26.9,5.5,54.7,3.1,82,5.7c29.6,2.8,58.6,11.6,84.8,25.6C541.7,112.9,567.6,133.4,597.8,136z"/>
</g>
<g id="Planet">
	<linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="506.457" y1="2.0701" x2="386.8414" y2="135.5436">
		<stop  offset="5.263160e-03" style="stop-color:#FFFFFF"/>
		<stop  offset="1" style="stop-color:#86DDD5"/>
	</linearGradient>
	<circle class="st5" cx="439.7" cy="76.6" r="36.4"/>
	<path class="st6" d="M499.3,56c-2.8-7.7-21.9-5.1-32.1-3.2c1.3,1.4,2.4,3,3.4,4.6c5.8-1,11.2-1.5,15.4-1.5c6.1,0.1,8,1.4,8.1,1.8
		c0.8,2.2-3.6,8.5-18.5,17c-0.8,0.5-1.7,1-2.6,1.5c-8.9,4.9-19.7,9.5-31.1,13.5c-9.2,3.2-18.1,5.8-26.6,7.6c-1.4,0.3-2.7,0.6-4,0.8
		c-20.5,4-29.6,1.8-30.5-0.7c-0.7-1.9,5.5-9.3,22.3-18.8c-0.2-2-0.1-4,0.1-6c-7.8,4.2-14.7,8.7-19.5,12.7c-6.7,5.6-9.3,10.1-8,13.8
		c3.5,9.7,27.8,5.9,39.4,3.5c0.3-0.1,0.5-0.1,0.8-0.2c0.2,0,0.4-0.1,0.5-0.1c8.7-1.9,17.8-4.5,27.3-7.8c11.5-4,22.3-8.7,31.4-13.6
		c0.2-0.1,0.4-0.2,0.5-0.3C485.9,75.2,502.5,64.6,499.3,56z"/>
</g>
<g id="Stars">
	<path class="st7" d="M148.2,131.7c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.2-0.2c0.9-4.8,3.7-7.9,8-8.8c0.1,0,0.2-0.1,0.1-0.2
		c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.2-0.2c-4.5-0.9-7.4-4.3-8.1-9.7c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.2
		c-0.8,5.3-3.8,8.7-8.4,9.5c-0.1,0-0.1,0.1-0.2,0.2c0,0,0,0,0,0c0,0.1,0.1,0.2,0.2,0.2c4.4,0.8,7.4,4,8.3,9
		C148.1,131.8,148.2,131.7,148.2,131.7z"/>
	<path class="st8" d="M62.9,227.3c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1-0.1c0.4-2.1,1.7-3.5,3.5-3.9c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0
		c0,0,0-0.1-0.1-0.1c-2-0.4-3.3-1.9-3.6-4.3c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c-0.4,2.4-1.7,3.8-3.7,4.2
		c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0.1,0,0.1,0.1,0.1C61.1,223.7,62.4,225.1,62.9,227.3C62.9,227.3,62.9,227.3,62.9,227.3z"/>
	<path class="st8" d="M561.4,187.6c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1-0.1c0.4-2.1,1.7-3.5,3.5-3.9c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0
		c0,0,0-0.1-0.1-0.1c-2-0.4-3.3-1.9-3.6-4.3c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c-0.4,2.4-1.7,3.8-3.7,4.2
		c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0.1,0,0.1,0.1,0.1C559.7,184,561,185.4,561.4,187.6C561.4,187.6,561.4,187.6,561.4,187.6z"/>
	<path class="st9" d="M478.7,365.1c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1-0.1c0.5-2.4,1.8-3.9,4-4.3c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0
		c0,0,0-0.1-0.1-0.1c-2.2-0.5-3.7-2.1-4-4.8c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c-0.4,2.6-1.9,4.3-4.1,4.7
		c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0.1,0,0.1,0.1,0.1C476.8,361.1,478.2,362.6,478.7,365.1C478.7,365.1,478.7,365.1,478.7,365.1z"/>
	<path class="st10" d="M156.9,317.3c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1-0.1c0.5-2.4,1.8-3.9,4-4.3c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0
		c0,0,0-0.1-0.1-0.1c-2.2-0.5-3.7-2.1-4-4.8c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c-0.4,2.6-1.9,4.3-4.1,4.7
		c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0.1,0,0.1,0.1,0.1C154.9,313.3,156.4,314.9,156.9,317.3C156.8,317.3,156.9,317.3,156.9,317.3z"/>
	<path class="st9" d="M417,424.1c0,1.2-1,2.2-2.2,2.2c-1.2,0-2.2-1-2.2-2.2s1-2.2,2.2-2.2C416,421.9,417,422.9,417,424.1z"/>
	<path class="st11" d="M506.5,131.8c0,1.2-1,2.2-2.2,2.2c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2
		C505.6,129.6,506.5,130.6,506.5,131.8z"/>
	<path class="st10" d="M307.4,455.7c0,2.8-2.2,5-5,5c-2.8,0-5-2.2-5-5c0-2.8,2.2-5,5-5C305.2,450.7,307.4,452.9,307.4,455.7z"/>
	<path class="st9" d="M189.5,220.9c0,1.2-1,2.2-2.2,2.2c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2
		C188.5,218.7,189.5,219.7,189.5,220.9z"/>
	<path class="st12" d="M138.4,191c0,4.1-3.3,7.4-7.4,7.4c-4.1,0-7.4-3.3-7.4-7.4c0-4.1,3.3-7.4,7.4-7.4
		C135.1,183.6,138.4,186.9,138.4,191z"/>
	<path class="st12" d="M557.6,90.2c0,4.1-3.3,7.4-7.4,7.4c-4.1,0-7.4-3.3-7.4-7.4c0-4.1,3.3-7.4,7.4-7.4
		C554.3,82.9,557.6,86.2,557.6,90.2z"/>
	<path class="st9" d="M355.4,423c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1-0.1c0.6-3,2.3-4.9,4.9-5.4c0.1,0,0.1-0.1,0.1-0.2c0,0,0,0,0-0.1
		c0-0.1,0-0.1-0.1-0.1c-2.8-0.6-4.6-2.7-5-6c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.1,0.1c-0.5,3.3-2.3,5.4-5.2,5.9
		c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0.1,0,0.1,0.1,0.1C353,418,354.8,420,355.4,423C355.4,423.1,355.4,423,355.4,423z"/>
	<path class="st9" d="M478.7,192.7c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.2-0.2c0.9-4.6,3.6-7.5,7.6-8.4c0.1,0,0.2-0.1,0.1-0.2
		c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.2c-4.3-0.9-7.1-4.1-7.8-9.2c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.2
		c-0.8,5.1-3.6,8.3-8,9.1c-0.1,0-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0.1,0.1,0.2,0.1,0.2c4.2,0.8,7,3.8,7.9,8.5
		C478.6,192.7,478.7,192.7,478.7,192.7z"/>
</g>
<g id="Main">
	<defs>
		<path id="SVGID_5_" d="M247.9,495c-125.1-22.8-76.1-99.1-204.4-195.2C-128.8,170.7,251.4-59.7,480.1,14.4
			c107.1,34.7,146,104.1,102.3,263.4C528.7,473.2,365,516.4,247.9,495z"/>
	</defs>
	<clipPath id="SVGID_6_">
		<use xlink:href="#SVGID_5_"  style="overflow:visible;"/>
	</clipPath>
	<linearGradient id="Fire_1_" gradientUnits="userSpaceOnUse" x1="9.636" y1="474.1556" x2="219.5637" y2="362.7916">
		<stop  offset="5.263160e-03" style="stop-color:#F8D9AF"/>
		<stop  offset="0.9895" style="stop-color:#E49B91"/>
	</linearGradient>
	<path id="Fire" class="st13" d="M205.3,324.8c0,0-33.9,27.2-59.7,13.5c-43.2-23-98.4,21.7-98.4,21.7l90.2,143.1
		c0,0,70.9-34.9,68-59.7c-2.9-24.8,0.2-49.1,28.8-51.5"/>
	<g id="Rocket" class="st14">
		<g>
			<linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="266.8191" y1="317.4507" x2="253.2043" y2="331.5517">
				<stop  offset="5.263160e-03" style="stop-color:#FFFFFF"/>
				<stop  offset="1" style="stop-color:#86DDD5"/>
			</linearGradient>
			<path class="st15" d="M252,389.6l-13.3,2.2c-6,1-12.1-1.4-15.9-6.2l0,0c-11.2-14.4-18.1-31.6-20.2-49.7v0c-0.7-6.1,2-12,7-15.5
				l11.1-7.7"/>
		</g>
		<g>
			<linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="257.3729" y1="268.4147" x2="244.9737" y2="276.4377">
				<stop  offset="5.263160e-03" style="stop-color:#FFFFFF"/>
				<stop  offset="1" style="stop-color:#86DDD5"/>
			</linearGradient>
			<path class="st16" d="M253.4,291.7c-7.1-13.5-31.1-31.3-77.6-1.1c-0.6,0.4-0.4,1.3,0.3,1.5c7.8,1.3,44.5,7.2,51.4,26.1"/>
		</g>
		<g class="st17">
			<g>
				<path class="st18" d="M185.9,292.8c-3.1-0.6-5.6-1-7.1-1.3c-0.7-0.1-0.8-1-0.3-1.4c43.2-28.1,65.5-11.6,72.1,1l-2.6,2.7
					C239.6,283.1,219.8,273.5,185.9,292.8z"/>
			</g>
		</g>
		<g>
			<path class="st19" d="M289.4,380.2c4.4,14.6,0,44.9-54.4,55.8c-0.7,0.1-1.2-0.7-0.8-1.3c4.7-6.4,26.5-37.1,18.2-55.5"/>
		</g>
		<g>
			<linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="318.9984" y1="363.6541" x2="306.5992" y2="371.6771">
				<stop  offset="5.263160e-03" style="stop-color:#FFFFFF"/>
				<stop  offset="1" style="stop-color:#86DDD5"/>
			</linearGradient>
			<path class="st20" d="M235,436c-0.7,0.1-1.2-0.7-0.8-1.3c2.4-3.3,9.3-12.9,14.3-24c4.8-10.5,7.9-22.3,3.9-31.3
				c0-0.1-0.1-0.1-0.1-0.2l0.2,0l36.8,1c1.2,4.1,1.8,9.4,0.7,15.2C287.4,410.3,274.1,428.1,235,436z"/>
		</g>
		<g>
			<linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="180.1944" y1="430.4591" x2="642.9755" y2="86.9289">
				<stop  offset="5.263160e-03" style="stop-color:#FFFFFF"/>
				<stop  offset="1" style="stop-color:#86DDD5"/>
			</linearGradient>
			<path class="st21" d="M508,240.1c0,0-11.4,78-111.7,127.2c-85.5,41.9-145.1,22.3-145.1,22.3c-12.2-13.3-21.1-29.2-26.3-46.2
				c-3-9.9-4.7-20.2-5-30.7c0,0,12.9-24.8,48.5-50.2c1.2-0.9,2.5-1.7,3.8-2.6c13.9-9.5,31-18.9,51.8-27.1c4.9-1.9,10-3.8,15.4-5.5
				c0.2-0.1,0.4-0.1,0.6-0.2c21.5-7,41.2-10.7,59-12c0.6,0,1.1-0.1,1.7-0.1C469,210.3,508,240.1,508,240.1z"/>
		</g>
		<g>
			<linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="398.4324" y1="282.6262" x2="434.9006" y2="270.227">
				<stop  offset="5.263160e-03" style="stop-color:#FFFFFF"/>
				<stop  offset="1" style="stop-color:#86DDD5"/>
			</linearGradient>
			<path class="st22" d="M457.5,326.1c-7.1-40-22.6-78-45.5-111.6c61.9-1.1,96.9,25.6,96.9,25.6S502.5,283.6,457.5,326.1z"/>
		</g>
		<g>
			<defs>
				<path id="SVGID_12_" d="M508,240.1c0,0-11.4,78-111.7,127.2c-85.5,41.9-145.1,22.3-145.1,22.3c-12.2-13.3-21.1-29.2-26.3-46.2
					c-3-9.9-4.7-20.2-5-30.7c0,0,12.9-24.8,48.5-50.2c1.2-0.9,2.5-1.7,3.8-2.6c13.9-9.5,31-18.9,51.8-27.1c4.9-1.9,10-3.8,15.4-5.5
					c0.2-0.1,0.4-0.1,0.6-0.2c21.5-7,41.2-10.7,59-12c0.6,0,1.1-0.1,1.7-0.1C469,210.3,508,240.1,508,240.1z"/>
			</defs>
			<clipPath id="SVGID_13_">
				<use xlink:href="#SVGID_12_"  style="overflow:visible;"/>
			</clipPath>
			<g class="st23">
				<linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="418.9483" y1="286.6653" x2="419.6777" y2="326.0509">
					<stop  offset="5.263160e-03" style="stop-color:#FFFFFF"/>
					<stop  offset="1" style="stop-color:#86DDD5"/>
				</linearGradient>
				<circle class="st24" cx="420.4" cy="364.7" r="38.6"/>
			</g>
			<g class="st23">
				<linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="406.0098" y1="401.0829" x2="447.5835" y2="296.0546">
					<stop  offset="5.263160e-03" style="stop-color:#FFFFFF"/>
					<stop  offset="1" style="stop-color:#86DDD5"/>
				</linearGradient>
				<circle class="st25" cx="420.6" cy="364.1" r="24.5"/>
			</g>
		</g>
		<path class="st26" d="M232.9,363.3l-19.6,0.7l-3.1,0.2c1.3,3,2.8,6,4.4,8.9c4.2,0.6,15.5,1.7,22.4-2.3c0,0,0,0,0,0
			C235.6,368.3,234.2,365.8,232.9,363.3z"/>
		<g>
			<linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="288.9355" y1="317.1933" x2="276.5364" y2="325.2163">
				<stop  offset="5.263160e-03" style="stop-color:#FFFFFF"/>
				<stop  offset="1" style="stop-color:#86DDD5"/>
			</linearGradient>
			<path class="st27" d="M245.9,362.7c-17.6,7.2-40.6,0.3-40.6,0.3s0-0.1,0.1-0.2c1.3-2.3,12.6-21.2,29.1-28
				c15.9-6.5,33.3-0.2,36.4,1c0.3,0.1,0.5,0.2,0.5,0.2S263.4,355.6,245.9,362.7z"/>
		</g>
		<g class="st28">
			<g>
				<path class="st9" d="M267.3,337.6c0,0-0.4,1-1.3,2.7c-5.2-1.7-18.1-5.1-30-0.2c-11.7,4.8-20.4,16.4-23.9,21.9
					c-1.8-0.4-2.8-0.7-2.8-0.7s0-0.1,0.1-0.2c1.2-2,11.1-18.6,25.6-24.5c13.9-5.7,29.2-0.2,31.9,0.9
					C267.2,337.5,267.3,337.6,267.3,337.6z"/>
			</g>
		</g>
	</g>
	<g id="Astronaut" class="st14 astronaut bounce-2">
		<radialGradient id="SVGID_17_" cx="497.9673" cy="156.0777" r="299.0533" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style="stop-color:#273B45;stop-opacity:0.2"/>
			<stop  offset="1" style="stop-color:#1B1A1E"/>
		</radialGradient>
		<path class="st29" d="M251.6,274.6c1.8-1.8,4.7-1.7,7.2-1.4c7.9,0.7,15.7,1.5,23.6,2.2c6.7,0.6,13.5,1.3,20.2,2
			c4.2,0.4,8.5,0.9,12.5,2.4c4,1.5,7.7,4.2,9.6,8c1.9,3.8,1.6,8.8-1.4,11.9c-1.7,1.7-4.1,2.7-6.6,2.6c-2.4-0.1-4.8-1.3-6.3-3.2
			c-0.9-1.1-1.6-2.5-3-3.1c-1.3-0.6-2.9-0.1-4.3,0.3c-7.1,1.9-14.9,1.4-21.7-1.4c-6.8-2.8-12.6-8-16.2-14.4
			c-5.9,1.2-12.2,0.7-17.9-1.5"/>
		<radialGradient id="SVGID_18_" cx="452.7468" cy="41.8107" r="516.8326" gradientUnits="userSpaceOnUse">
			<stop  offset="0.3182" style="stop-color:#E1E2E4"/>
			<stop  offset="1" style="stop-color:#A3A6A9"/>
		</radialGradient>
		<path class="st30" d="M305.3,199.9c28.6,1.9,33.4,13.9,40.4,23.2c2.9,3.8,14.6,7.6,20.3,1.6c6.4-6.7,3.8-14.7,0.9-19.1
			c-18.6-28.2-62.7-29.8-62.7-29.8"/>
		<radialGradient id="SVGID_19_" cx="452.7468" cy="41.8327" r="516.8029" gradientUnits="userSpaceOnUse">
			<stop  offset="0.3182" style="stop-color:#E1E2E4"/>
			<stop  offset="1" style="stop-color:#A3A6A9"/>
		</radialGradient>
		<path class="st31" d="M221.7,213.3c0.8,11.6,9.2,39.1,24.9,62.1c6.3,5,21,0.3,21,0.3l-14-61l1.1-27.4l-2.5-3.9
			C252.1,183.4,220.8,201.7,221.7,213.3z"/>
		<radialGradient id="SVGID_20_" cx="452.7325" cy="96.3379" r="446.6954" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style="stop-color:#273B45;stop-opacity:0.2"/>
			<stop  offset="1" style="stop-color:#1B1A1E"/>
		</radialGradient>
		<path class="st32" d="M267.5,275.8l-13.8-60.4l-3.2-10.2c0,0-10.4,5.2-28.8,6.7c-0.1,0.5-0.1,0.9-0.1,1.4
			c0.8,11.6,9.2,39.1,24.9,62.1C252.9,280.4,267.5,275.8,267.5,275.8z"/>
		<radialGradient id="SVGID_21_" cx="321.4613" cy="107.9397" r="289.6405" gradientUnits="userSpaceOnUse">
			<stop  offset="0.369" style="stop-color:#F8FBFF"/>
			<stop  offset="1" style="stop-color:#E1E2E4"/>
		</radialGradient>
		<path class="st33" d="M252,185.2c0,0-6.2,44.7,5.8,73.2c12,28.4,25.3,36.4,35.7,34.9s16.7-1.4,20.7,0.1l10.4-33.3l29.4-24.8
			l-12.6-21.2c0,0-21-31.4-32.8-43.2"/>
		<g>
			<defs>
				<circle id="SVGID_22_" cx="270.6" cy="138.8" r="50"/>
			</defs>
			<clipPath id="SVGID_23_">
				<use xlink:href="#SVGID_22_"  style="overflow:visible;"/>
			</clipPath>
			<g class="st34">
				<radialGradient id="SVGID_24_" cx="364.7369" cy="51.5356" r="315.5458" gradientUnits="userSpaceOnUse">
					<stop  offset="0.3182" style="stop-color:#E1E2E4"/>
					<stop  offset="1" style="stop-color:#A3A6A9"/>
				</radialGradient>
				<circle class="st35" cx="270.6" cy="138.8" r="50"/>
			</g>
		</g>
		<radialGradient id="SVGID_25_" cx="497.9673" cy="156.0777" r="299.0533" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style="stop-color:#273B45"/>
			<stop  offset="1" style="stop-color:#1B1A1E"/>
		</radialGradient>
		<polygon class="st36" points="324.5,287.8 355.4,283.1 417.3,222.6 325.7,273 		"/>
		<linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="412.2421" y1="262.9875" x2="393.0963" y2="238.7362">
			<stop  offset="5.263160e-03" style="stop-color:#F8D9AF"/>
			<stop  offset="0.9895" style="stop-color:#E49B91"/>
		</linearGradient>
		<polygon class="st37" points="426.3,214.9 360.4,270.9 360.1,262.8 426,206.9 		"/>
		<linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="307.7868" y1="272.6099" x2="360.4252" y2="272.6099">
			<stop  offset="5.263160e-03" style="stop-color:#F8D9AF"/>
			<stop  offset="0.9895" style="stop-color:#E49B91"/>
		</linearGradient>
		<polygon class="st38" points="308.1,282.4 360.4,270.9 360.1,262.8 307.8,274.4 		"/>
		<linearGradient id="SVGID_28_" gradientUnits="userSpaceOnUse" x1="332.6844" y1="266.7396" x2="423.444" y2="197.9">
			<stop  offset="5.263160e-03" style="stop-color:#F8D9AF"/>
			<stop  offset="0.9895" style="stop-color:#E49B91"/>
		</linearGradient>
		<polygon class="st39" points="374.5,218.1 426,206.9 360.1,262.8 307.8,274.4 		"/>
		<radialGradient id="SVGID_29_" cx="452.7468" cy="41.8107" r="516.8326" gradientUnits="userSpaceOnUse">
			<stop  offset="0.3182" style="stop-color:#E1E2E4"/>
			<stop  offset="1" style="stop-color:#A3A6A9"/>
		</radialGradient>
		<path class="st40" d="M331.2,280.6c0.1-12.1-13.2-32.1-13.2-32.1s-8-13.9-21-2.9c-13,11,17.3,47.8,17.3,47.8
			S331.5,300.1,331.2,280.6z"/>
		<linearGradient id="SVGID_30_" gradientUnits="userSpaceOnUse" x1="363.1839" y1="173.184" x2="396.9169" y2="226.9745">
			<stop  offset="5.263160e-03" style="stop-color:#F8D9AF"/>
			<stop  offset="0.9895" style="stop-color:#E49B91"/>
		</linearGradient>
		<path class="st41" d="M403.2,148.1l-61.5,52.2c-0.8,0.7-1.2,1.8-0.8,2.9l19.2,59.7l65.9-55.9l-18.6-57.6
			C406.9,147.6,404.6,146.9,403.2,148.1z"/>
		<radialGradient id="SVGID_31_" cx="452.7468" cy="41.8107" r="516.8326" gradientUnits="userSpaceOnUse">
			<stop  offset="0.3182" style="stop-color:#E1E2E4"/>
			<stop  offset="1" style="stop-color:#A3A6A9"/>
		</radialGradient>
		<path class="st42" d="M260.7,218.9c0,0,37.3,28.5,69.6,13.3c4.7-2.2,12.5-5.5,10-16.2c-2.5-10.7-11.8-8.6-16.4-7.4
			c-11.3,2.7-20.9,8.2-44.3-8.4c0,0-11.1-6.7-16.7,0C257.3,206.9,253.7,213.9,260.7,218.9z"/>
		<radialGradient id="SVGID_32_" cx="364.2038" cy="79.1528" r="245.6737" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style="stop-color:#273B45;stop-opacity:0.2"/>
			<stop  offset="1" style="stop-color:#1B1A1E"/>
		</radialGradient>
		<path class="st43" d="M264,107.2c-19.9,8.1-34.8,21.2-43.4,30.1c-0.2,6.6,0.9,13.3,3.4,19.8c4.4,11.2,12.4,19.9,22.1,25.3
			c47-2.6,73.9-37.6,74-37.6c0,0,0,0,0,0L264,107.2z"/>
		<g>
			<radialGradient id="SVGID_33_" cx="345.7735" cy="84.1138" r="204.611" gradientUnits="userSpaceOnUse">
				<stop  offset="0.369" style="stop-color:#F8FBFF"/>
				<stop  offset="1" style="stop-color:#E1E2E4"/>
			</radialGradient>
			<path class="st44" d="M245.4,150.5c-1.4-3.7-5.1-5.8-8.9-5.3c-0.7,0.1-1.4,0.3-2.1,0.5c0,0,0,0-0.1,0l0,0
				c-2.2,0.9-3.8,2.5-4.6,4.5c0,0,0,0,0,0c-0.1,0.2-0.2,0.5-0.3,0.7c0,0,0,0.1,0,0.1c-0.1,0.2-0.1,0.4-0.2,0.7c0,0.1,0,0.1,0,0.2
				c0,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.2,0,0.2c0,0.2,0,0.4,0,0.6c0,0.1,0,0.2,0,0.3c0,0.2,0,0.3,0,0.5c0,0.1,0,0.3,0.1,0.4
				c0,0.1,0,0.3,0.1,0.4c0,0.2,0.1,0.4,0.1,0.5c0,0.1,0,0.2,0.1,0.3c0.1,0.3,0.2,0.5,0.3,0.8c1.4,3.7,5.1,5.8,8.9,5.3
				c0.7-0.1,1.4-0.3,2.1-0.5c0,0,0,0,0.1,0C245,159.7,247.1,154.8,245.4,150.5z"/>
			<radialGradient id="SVGID_34_" cx="345.7735" cy="84.1138" r="204.611" gradientUnits="userSpaceOnUse">
				<stop  offset="0.369" style="stop-color:#F8FBFF"/>
				<stop  offset="1" style="stop-color:#E1E2E4"/>
			</radialGradient>
			<path class="st45" d="M323.5,124.3l-4.4-11.2c-3.1-7.9-10.8-13.1-19.3-13h0c-22.4,0.2-44,8.1-61.3,22.4v0
				c-5.5,4.5-7.7,11.9-5.9,18.6c1.1-0.4,2.2-0.7,3.3-0.8h0c6-0.7,11.8,2.8,14,8.4c2.6,6.6-0.5,14.1-6.8,17c3.6,4.3,9.1,6.9,14.9,6.5
				l0.1,0c22.3-1.3,43.5-10.2,60-25.3l0,0C324.5,141.2,326.6,132.2,323.5,124.3z"/>
		</g>
		<radialGradient id="SVGID_35_" cx="452.7297" cy="72.2599" r="466.8751" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style="stop-color:#273B45;stop-opacity:0.2"/>
			<stop  offset="1" style="stop-color:#1B1A1E"/>
		</radialGradient>
		<path class="st46" d="M295.2,247.8c-7.7,13,19.1,45.6,19.1,45.6s17.2,6.7,16.9-12.8c0-0.6,0-1.2-0.1-1.9c-0.1,0-0.3,0-0.4,0
			c-1.9,0.3-3.5,1.6-5.3,2.4c-4.2,1.9-9.3,0.6-13-2.1s-6.3-6.6-8.8-10.5C299.7,262.1,295.8,255.3,295.2,247.8z"/>
		<radialGradient id="SVGID_36_" cx="551.086" cy="45.9072" r="332.2174" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style="stop-color:#273B45;stop-opacity:0.2"/>
			<stop  offset="1" style="stop-color:#1B1A1E"/>
		</radialGradient>
		<path class="st47" d="M413.7,214.7l-48.4,41c-0.7,0.6-1.8,0.3-2.1-0.6l-11.7-35.5c-0.4-1.1,0.8-2.1,1.9-1.6
			c24,13.7,48.9,2.5,59.9-4.1c0,0,0.4-0.2,0.6,0.1C414,214.4,413.7,214.7,413.7,214.7z"/>
		<radialGradient id="SVGID_37_" cx="437.3196" cy="53.2824" r="483.8754" gradientUnits="userSpaceOnUse">
			<stop  offset="0" style="stop-color:#273B45;stop-opacity:0.2"/>
			<stop  offset="1" style="stop-color:#1B1A1E"/>
		</radialGradient>
		<path class="st48" d="M330.3,232.2c1.2-0.6,2.5-1.2,3.9-2c0,0-26.4-3.7-54-15.8c-6.6-2.9-17.4-9.3-16.7-14.9
			c-0.2,0.2-0.4,0.4-0.6,0.6c-5.6,6.7-9.2,13.7-2.2,18.7C260.7,218.9,297.9,247.4,330.3,232.2z"/>
	</g>
</g>
</svg>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            texts: ["<span class='highlighted'>megvalósítani ötleteidet </span> 🦸", "<span class='highlighted'>feltúrbozni a vállalkozásod </span> 🚀", "<span class='highlighted'>eligazodni a digitális térben </span> 💻"]
        }
    }
}
</script>

<style >
	.st0{fill:url(#Background_1_);}
	.st1{opacity:0.49;}
	.st2{fill:url(#SVGID_1_);}
	.st3{opacity:0.3;fill:url(#SVGID_2_);}
	.st4{opacity:0.2;fill:url(#SVGID_3_);}
	.st5{fill:url(#SVGID_4_);}
	.st6{opacity:0.3;fill:#FFFFFF;}
	.st7{opacity:0.75;fill:#FFFFFF;}
	.st8{opacity:0.5;fill:#FFFFFF;}
	.st9{opacity:0.25;fill:#FFFFFF;}
	.st10{opacity:0.4;fill:#FFFFFF;}
	.st11{opacity:0.79;fill:#FFFFFF;}
	.st12{opacity:0.1;fill:#FFFFFF;}
	.st13{clip-path:url(#SVGID_6_);fill:url(#Fire_1_);}
	.st14{clip-path:url(#SVGID_6_);}
	.st15{fill:url(#SVGID_7_);}
	.st16{fill:url(#SVGID_8_);}
	.st17{opacity:0.25;}
	.st18{fill:#FFFFFF;}
	.st19{fill:#D25658;}
	.st20{fill:url(#SVGID_9_);}
	.st21{fill:url(#SVGID_10_);}
	.st22{fill:url(#SVGID_11_);}
	.st23{clip-path:url(#SVGID_13_);}
	.st24{fill:url(#SVGID_14_);}
	.st25{fill:url(#SVGID_15_);}
	.st26{opacity:0.1;fill:#4D5257;}
	.st27{fill:url(#SVGID_16_);}
	.st28{opacity:0.6;}
	.st29{opacity:6.000000e-02;fill:url(#SVGID_17_);}
	.st30{fill:url(#SVGID_18_);}
	.st31{fill:url(#SVGID_19_);}
	.st32{opacity:0.1;fill:url(#SVGID_20_);}
	.st33{fill:url(#SVGID_21_);}
	.st34{clip-path:url(#SVGID_23_);}
	.st35{fill:url(#SVGID_24_);}
	.st36{opacity:6.000000e-02;fill:url(#SVGID_25_);}
	.st37{fill:url(#SVGID_26_);}
	.st38{fill:url(#SVGID_27_);}
	.st39{fill:url(#SVGID_28_);}
	.st40{fill:url(#SVGID_29_);}
	.st41{fill:url(#SVGID_30_);}
	.st42{fill:url(#SVGID_31_);}
	.st43{opacity:0.1;fill:url(#SVGID_32_);}
	.st44{fill:url(#SVGID_33_);}
	.st45{fill:url(#SVGID_34_);}
	.st46{opacity:0.1;fill:url(#SVGID_35_);}
	.st47{opacity:3.000000e-02;fill:url(#SVGID_36_);}
    .st48{opacity:0.1;fill:url(#SVGID_37_);}
    .astronaut {
        align-self: flex-end;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        margin: 0 auto 0 auto;
        transform-origin: bottom;
    }
    .bounce-2 {
        animation-name: bounce-2;
        animation-timing-function: ease;
    }
    @keyframes bounce-2 {
        0%   { transform: translateY(0); }
        50%  { transform: translateY(-60px); }
        100% { transform: translateY(0); }
    }
    @keyframes shake {
        10%, 90% {
            transform: translate3d(-1px, 0, 0);
        }
        
        20%, 80% {
            transform: translate3d(2px, 0, 0);
        }

        30%, 50%, 70% {
            transform: translate3d(-4px, 0, 0);
        }

        40%, 60% {
            transform: translate3d(4px, 0, 0);
        }
    }
</style>