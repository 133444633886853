<template>
    <header class="section header-area">
        <div id="appo-header" class="main-header-area">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-md navbar-light">
                    <!-- Logo -->
                    <a class="navbar-brand" href="/">
                        <img class="logo" src="assets/img/logo.png" alt="">
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#appo-menu">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <!-- Appo Menu -->
                    <div class="collapse navbar-collapse" id="appo-menu">
                        <!-- Header Items -->
                        <ul class="navbar-nav header-items ml-auto">
                            <li class="nav-item active dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown">
                                    Home
                                </a>
                                <div class="dropdown-menu mega-menu px-3 px-md-4 py-md-4">
                                    <!-- Shapes Container -->
                                    <div class="shapes-container d-none d-lg-block">
                                        <div class="shape-1"></div>
                                    </div>
                                    <div class="row">
                                        <!-- Dropdown Headings -->
                                        <div class="col-lg-3 dropdown-headings d-none d-lg-block">
                                            <span class="fw-6">Appo</span>
                                            <h5 class="text-uppercase my-2">Creative Design</h5>
                                            <p class="slag fw-5">Verified by developers &amp; Truted by tons of customers</p>
                                            <div class="dropdown-btn mt-3">
                                                <a href="#" class="btn btn-bordered">Get Started</a>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-3">
                                            <!-- Single Menu -->
                                            <ul class="single-menu">
                                                <li class="page-title d-inline-block text-uppercase mb-2">Home Variations</li>
                                                <li><a class="dropdown-item" href="/">Homepage - Miami</a></li>
                                                <li><a class="dropdown-item" href="/theme-two">Homepage - Riga</a></li>
                                                <li><a class="dropdown-item" href="/theme-three">Homepage - Tokyo</a></li>
                                                <li><a class="dropdown-item" href="/theme-four">Homepage - Rome</a></li>
                                                <li><a class="dropdown-item" href="/theme-five">Homepage - Paris</a></li>
                                                <li><a class="dropdown-item" href="/theme-six">Homepage - London</a></li>
                                                <li><a class="dropdown-item" href="/theme-seven">Homepage - Seoul</a></li>
                                                <li><a class="dropdown-item" href="/theme-eight">Homepage - Moscow</a></li>
                                            </ul>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-3">
                                            <!-- Single Menu -->
                                            <ul class="single-menu">
                                                <li class="page-title d-inline-block text-uppercase mb-2">Inner Pages</li>
                                                <li><a class="dropdown-item" href="/theme-nine">Homepage - Italy <span class="badge badge-pill badge-warning ml-1">New</span></a></li>
                                                <li><a class="dropdown-item" href="/about">About Us</a></li>
                                                <li><a class="dropdown-item" href="/pricing">Pricing Plans</a></li>
                                                <li><a class="dropdown-item" href="/reviews">Testimonials</a></li>
                                                <li><a class="dropdown-item" href="/faq">FAQ</a></li>
                                                <li><a class="dropdown-item" href="/login">Login</a></li>
                                                <li><a class="dropdown-item" href="/signup">Register</a></li>
                                                <li><a class="dropdown-item" href="/download">Download</a></li>
                                            </ul>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-3">
                                            <!-- Single Menu -->
                                            <ul class="single-menu">
                                                <li class="page-title d-inline-block text-uppercase mb-2">Inner Pages</li>
                                                <li><a class="dropdown-item" href="/thank-you">Thank You</a></li>
                                                <li><a class="dropdown-item" href="/reset">Forget Password</a></li>
                                                <li><a class="dropdown-item" href="/newsletter">Newsletter</a></li>
                                                <li><a class="dropdown-item" href="/error-one">Error v1.0</a></li>
                                                <li><a class="dropdown-item" href="/error-two">Error v2.0</a></li>
                                                <li><a class="dropdown-item" href="/contact">Contact</a></li>
                                                <li><a class="dropdown-item" href="/maintenance">Maintenance</a></li>
                                                <li><a class="dropdown-item" href="/coming-soon">Coming Soon</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link scroll" href="#features">Features</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link scroll" href="#pricing">Pricing</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link scroll" href="#team">Team</a>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown-2" role="button" data-toggle="dropdown">
                                    Blog
                                </a>
                                <!-- Blog Menu -->
                                <div class="dropdown-menu mega-menu blog-menu px-3 py-md-3">
                                    <div class="row">
                                        <div class="col-12">
                                            <ul class="single-menu">
                                                <li><a class="dropdown-item" href="/blog-two-column">Blog - 2 Column</a></li>
                                                <li><a class="dropdown-item" href="/blog-three-column">Blog - 3 Column</a></li>
                                                <li><a class="dropdown-item" href="/blog-left-sidebar">Blog - Left Sidebar</a></li>
                                                <li><a class="dropdown-item" href="/blog-right-sidebar">Blog - Right Sidebar</a></li>
                                                <li><a class="dropdown-item" href="/blog-details-left-sidebar">Blog Details - Left Sidebar</a></li>
                                                <li><a class="dropdown-item" href="/blog-details-right-sidebar">Blog Details - Right Sidebar</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link scroll" href="#contact">Contact</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
export default {
  
}
</script>

<style>

</style>